import React from 'react';
import { API, Config, Tools } from '../../utils/utils';
import { LoaderView, LoaderPage, ErrorComponent } from '../UI';
import _ from 'lodash';
import '../../css/cards.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export class CardPage extends React.Component {
    render() {
        const { match: { params } } = this.props;
        return <CardLoader menuId={params.menuId} showBackground={true} />
    }
}

export class CardPopup extends React.Component {
    render() {
        return (
            <div className={"card-popup " + this.props.className}>
                <div onClick={this.props.hide} style={{
                    position: "fixed",
                    width: "100%",
                    height: "100%"
                }}></div>
                <CardViewer menu={this.props.menu} />
            </div>
        );
    }
}

export class CardViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            themes: {}
        }
    }

    render() {
        const menu = this.props.menu || {};

        // We need a themeName in order to know how to draw this menu
        let themeName = Tools.themeId(menu.themeName);
        let watermarkClasses = ["watermark"];
        if (Config.usesLightBackground(themeName)) {
            watermarkClasses.push("dark");
        }
        const courses = menu.courses || [];
        const title = menu.title || "Menu"

        const hasContent = courses.length > 0 || menu.description;

        // Draw the card
        return <>
            <Helmet>
                {/* https://github.com/nfl/react-helmet */}
                <meta property="og:title" content={title} />
            </Helmet>
            <div className={"menucard-wrapper theme-" + themeName}>
                <div className="menucard-background">
                    <div className="menucard-container">
                        <div className="menucard">
                            <div className="corner-element top left"></div>
                            <div className="corner-element top right"></div>
                            <div className="corner-element bottom left"></div>
                            <div className="corner-element bottom right"></div>

                            <div className="menucard-content">
                                <span className="title menu-title">{title}</span>
                                {!hasContent && <span className="explanation">Voeg je eerste gang toe in het menu links.</span>}
                                {hasContent && <>
                                    <span className="body menu-description">{menu.description}</span>
                                    <div className="menu-courses">
                                        {courses.map(function (course, i) {
                                            if (!course.id) {
                                                return null;
                                            }

                                            return <><div className="course" key={course.id}>
                                                <span className="body type">{course.type}</span>
                                                <span className="title name">{course.name}</span>
                                                <span className="body description">{course.description}</span>
                                            </div>
                                                {i < courses.length - 1 ? <div className="separator"></div> : null}
                                            </>
                                        })}
                                    </div>
                                </>}

                                <Link to="/">
                                    <div className={watermarkClasses.join(" ")}></div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export class CardLoader extends React.Component {
    constructor(props) {
        super(props);
        this.reload = this.reload.bind(this);
        this.state = {
            isLoading: true,
            error: null
        };
    }

    componentDidMount() {
        this.reload()
    }

    reload() {
        API.loadMenu(this.props.menuId).then(function (menuData) {
            this.setState(function () {
                return {
                    ...menuData,
                    isLoading: false,
                    error: null
                }
            })
        }.bind(this)).catch(function (err) {
            console.log(err);
            this.setState(function (state) {
                return {
                    error: err,
                    isLoading: false
                }
            })
        }.bind(this));
    }

    render() {
        if (this.state.isLoading) {
            return <LoaderPage />
        } else if (this.state.error != null) {
            return <ErrorComponent error={String(this.state.error)} reload={this.reload} />
        } else {
            return <CardViewer menu={this.state} showBackground={this.props.showBackground} />
        }
    }
}