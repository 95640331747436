import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Container } from 'reactstrap';

export function Dialog(props) {
    return <Modal size={props.size || "md"} isOpen={props.isOpen} toggle={props.toggle}>
        <ModalBody>
            {props.children}
        </ModalBody>
    </Modal>
}

export function ConfirmBox(props) {
    return <Modal size="sm" isOpen={props.isOpen} toggle={props.toggle}>
        {props.title && <ModalHeader>{props.title}</ModalHeader>}
        {props.body && <ModalBody>{props.body}</ModalBody>}
        <ModalFooter>
            
            {props.cancel && <Button
                color="secondary"
                onClick={props.cancel}>
                {props.cancelText || "Cancel"}
            </Button>}

            <Button
                color={props.confirmColor || "primary"}
                onClick={props.confirm}>
                {props.confirmText || "Okay"}
            </Button>
        
        </ModalFooter>
    </Modal>
}

export function LoaderPage() {
    return <div className="loader-container"><Loader /></div>
}

export function LoaderView() {
    return <div className="loader-view"><Loader /></div>
}

export function Loader() {
    return <div className="loader"></div>
}

export function ErrorComponent(props) {
    let button = <></>;
    if (props.reload) {
        button = <button onClick={props.reload}>Reload</button>
    }

    return <div className="error">
        <p>Er ging iets niet helemaal goed...</p>
        <p>{props.error}</p>
        {button}
    </div>
}



export function MainContainer(props) {
    return <>
        <Container className="main-container">
            {props.children}
        </Container>
    </>
}

{/* <a href="https://www.instagram.com/menukaartjecom" target="_blank" className="icon instagram"></a> */}

export function Footer(props) {
    return <div className="footer">
        <p>© 2020 <a href="/">Menukaartje.com</a></p>
    </div>
}