import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './css/custom.scss';
import './css/style.css';
import * as firebase from 'firebase'

firebase.initializeApp({
    apiKey: "AIzaSyD6kDVRXM8kYjT59hRG3G5gpC_UXwf3aVI",
    authDomain: "menukaartje-3fa5b.firebaseapp.com",
    databaseURL: "https://menukaartje-3fa5b.firebaseio.com",
    projectId: "menukaartje-3fa5b",
    storageBucket: "menukaartje-3fa5b.appspot.com",
    messagingSenderId: "1032535469009",
    appId: "1:1032535469009:web:3c76c3c1435dcda2a58ef0",
    measurementId: "G-KSM5PPD26G"
});
firebase.analytics();

ReactDOM.render(<App/>, document.getElementById('root'));