import 'aframe';
import React from 'react';
import { Link } from 'react-router-dom';
import { API, Tools } from '../../utils/utils';
import { MainContainer, LoaderPage } from '../UI';
var QRCode = require('qrcode.react');

export class QRPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            themeName: null
        };
    }

    componentDidMount() {
        this.reload()
    }

    reload() {
        const { match: { params } } = this.props;
        API.loadMenu(params.menuId).then(function (menuData) {
            console.log(menuData)
            this.setState({
                themeName: menuData.themeName,
                isLoading: false
            });
        }.bind(this)).catch(function (error) {
            this.setState({
                themeName: null,
                isLoading: false
            })
        }.bind(this));
    }

    render() {
        const { match: { params } } = this.props;
        
        if (this.state.isLoading) {
            return <MainContainer><LoaderPage /></MainContainer>
        } else {
            let themeName = Tools.themeId(this.state.themeName);
            let backgroundStyle = {
                backgroundImage: "url(\"/images/themes/" + themeName + "/" + themeName + "-background.jpg\")"
            };

            return <>
                <div className="qr-container" style={backgroundStyle}>
                    <div className="qr-element">
                        <div className="qr-code">
                            <Link to={`/v/${params.menuId}`}>
                                <QRCode value={`https://menukaartje.com/v/${params.menuId}/`} />
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        }
    }
}