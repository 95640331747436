import React from 'react';
import { Redirect } from 'react-router-dom';
import { Dialog } from './UI';
import { API } from '../utils/utils';
import { Button } from 'reactstrap';

export class NewMenuPopup extends React.Component {
    state = {
        isLoading: false,
        menuName: "",
        menuId: null
    }

    constructor(props) {
        super(props);

        this.createButtonHandler = this.createButtonHandler.bind(this);
        this.textInputChangeHandler = this.textInputChangeHandler.bind(this);
    }

    textInputChangeHandler(event) {
        this.setState({ menuName: event.target.value });
    }

    createButtonHandler(e) {
        console.log("Hi there");
        API.createMenu(this.state.menuName).then(function (newMenuId) {
            this.setState({ menuId: newMenuId })
        }.bind(this));
    }

    render() {
        // als we een menuId hebben, is hij aangemaakt en kunnen we hier weg :)
        if (this.state.menuId) {
            return <Redirect to={"/edit/" + this.state.menuId} />
        }

        return (
            <Dialog size="sm" isOpen={this.props.isOpen} toggle={this.props.cancel}>
                <h1>Nieuw menukaartje</h1>
                <p>Geef je menukaartje een naam, dit kan van alles zijn. Je kan het later nog aanpassen.</p>
                <input
                    autoFocus
                    type="text"
                    placeholder="Naam"
                    value={this.state.menuName}
                    onChange={this.textInputChangeHandler}
                />
                <div className="spacer" />
                <Button color="primary" block disabled={this.state.menuName.length === 0} className="primary" onClick={this.createButtonHandler}>Create</Button>
                <Button color="light" block onClick={this.props.toggle}>Cancel</Button>
            </Dialog>
        )
    }
}