import 'aframe';
import React from 'react';

export class ARPage extends React.Component {
    render() {
        /*
        <Scene>
                
                <Box position="-1 0.5 -3" rotation="0 45 0" color="#4CC3D9" shadow />
                <Plane position="0 0 -4" rotation="-90 0 0" width="4" height="4" color="#7BC8A4" shadow />

                <a-entity geometry="primitive: plane"
                    width="40" height="40"
                    position="0 1.25 -5"
                    material="src: /logo1024.png;"></a-entity>
                </Scene >
        */
        return (
            <a-scene embedded arjs>
                <a-box position='0 0.5 0' material='opacity: 0.5;'></a-box>
                <a-marker-camera preset='hiro'></a-marker-camera>
            </a-scene>
        );
    }
}