import React from 'react';
import firebase from 'firebase/app';
import Editor from './components/pages/Editor';
import { CardPage } from './components/pages/Viewer';
import { ARPage } from './components/pages/AR';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AppContext from './contexts/AppContext';
import { LoaderPage, MainContainer } from './components/UI';
import { HomePage } from './components/pages/HomePage';
import { Menu } from './components/Menu';
import { QRPage } from './components/pages/QRPage';
import  { Helmet } from 'react-helmet';
import { AboutPage } from './components/pages/About';

class App extends React.Component {
    state = {
        userName: "...",
        isLoading: true
    }

    constructor(props) {
        super(props);

        firebase.auth().onAuthStateChanged(function (user) {
            this.setState({
                isLoading: false,
                user: user,
                userName: (user || {}).displayName
            });
        }.bind(this));
    }

    render() {
        if (this.state.isLoading) {
            // waiting...
            return <MainContainer><LoaderPage /></MainContainer>
        } else {
            const isLoggedIn = this.state.user || false;

            return <AppContext.Provider value={{ ...this.state }}>
                <Router>
                    <Switch>
                        <Route path="/(v|view)/:menuId" exact component={CardPage} />
                        <Route path="/ar/:menuId" exact component={ARPage} />
                        <Route path="/qr/:menuId" exact component={QRPage} />
                        <Route path="/about" exact component={AboutPage} />

                        {isLoggedIn && <Route path="/cards" exact component={Menu} />}
                        {isLoggedIn && <Route path="/profile" exact component={Menu} />}
                        {isLoggedIn && <Route path="/edit/:menuId" component={Editor} />}

                        <Route path="/" component={HomePage} />
                    </Switch>
                </Router>
            </AppContext.Provider>
        }
    }
}

export default App;