import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { MKNavBar } from '../MKNavBar';
import { MainContainer, LoaderPage } from '../UI';

export class AboutPage extends React.Component {
    render() {
        return <MainContainer>
            <MKNavBar />
            <Container className="content-page">
                <Row>
                    <Col md="12">
                        <h1>Over</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md="8">
                        <p>Het idee voor <b>Menukaartje.com</b> is één dag voor het kerstdiner ontstaan. <i>“Wie maakt eigenlijk de menukaartjes?”</i> werd er opeens gevraagd. Wie is er handig met Photoshop, of Word? Uiteindelijk was er geen menukaartje.</p>
                        <p>Een menukaartje is niet het belangrijkste onderdeel van een diner, maar het maakt jouw diner net iets specialer en geeft je gasten iets om over te praten terwijl ze op het eten wachten.</p>
                        <p>Hoe moeilijk kan het zijn, dacht ik. Er moet toch wel ergens een tooltje te vinden zijn die dit voor ons op kan lossen? Maar ik kon niets makkelijks vinden. Menukaartje.com was geboren.</p>
                        <p>Menukaartje is ontworpen en ontwikkeld door mij, Gertjan Smits. Ik ben een app ontwikkelaar met te veel vrije tijd. Menukaartje verdient geen geld, het project is ontstaan uit bovenstaande situatie en een gezonde dosis interesse om nieuwe technieken te verkennen. Dit is zo ongeveer de eerste website die ik gemaakt heb.</p>
                        <p>Heb je vragen, opmerkingen of tips? Stuur een mailtje naar <a href="mailto:info@menukaartje.com">info@menukaartje.com</a> of stuur een bericht via <a href="https://www.instagram.com/menukaartjecom" target="_blank">Instagram</a>.</p>
                    </Col>
                    <Col md="4">
                        <div className="side-column-feature about"></div>
                    </Col>
                </Row>
            </Container>
        </MainContainer>
    }
}