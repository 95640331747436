import React from 'react';
import { MKNavBar } from "../MKNavBar";
import AppContext from '../../contexts/AppContext';
import { MainContainer, Footer } from "../UI";
import { Redirect } from 'react-router-dom';
import { LoginBox } from '../AuthUI';
import { Container, Row, Col, Button, Alert } from 'reactstrap';

export class HomePage extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            redirectTo: null,
            loginBoxIsOpen: false
        }
    }

    hideLoginBox() {
        this.setState({
            loginBoxIsOpen: false
        });
    }


    render() {
        if (this.state.redirectTo) {
            return <Redirect to={this.state.redirectTo} />
        }

        return <>
            <MainContainer>
                <MKNavBar />
                
                <div className="feature-header">
                    <div className="feature-header-content">
                        <h1>Maak je diner compleet.</h1>
                        <p>Maak indruk op je vrienden met een menukaartje voor jouw thuisdiner!</p>
                        {/* <Link to="/cards"> */}
                        <Button color="primary" onClick={() => {
                            if (this.context.user) {
                                this.setState({ redirectTo: "/cards" });
                            } else {
                                this.setState({ loginBoxIsOpen: true });
                            }
                        }}>Begin hier!</Button>
                        {/* </Link> */}
                    </div>
                </div>

                {this.state.loginBoxIsOpen && <LoginBox isOpen={this.state.loginBoxIsOpen} toggle={this.hideLoginBox.bind(this)} />}

                <Container>
                    <Row>
                        <Col md="4" className="usp">
                            <div className="usp-content">
                                <img src="/images/dinner.svg" alt="Dinner icon" />
                                <h3>Makkelijk & Gratis</h3>
                                <p>Een menukaartje maken is simpel en kost helemaal niets.</p>
                            </div>
                        </Col>
                        <Col md="4" className="usp">
                            <div className="usp-content">
                                <img src="/images/kwast.svg" alt="Uniek icon" />
                                <h3>Uniek</h3>
                                <p>Een menukaartje maakt je diner af en breekt meteen het ijs.</p>
                            </div>
                        </Col>
                        {/* <Col md="4" className="usp">
                            <div className="usp-content">
                                <img src="/images/kwast.svg" alt="Themes icon" />
                                <h3>Thema's</h3>
                                <p>Er zijn meerdere thema's voor iedere gelegenheid.</p>
                            </div>
                        </Col> */}
                        <Col md="4" className="usp">
                            <div className="usp-content">
                                <img src="/images/qr.svg" alt="QR Code icon" />
                                <h3>Interactief</h3>
                                <p>Je gasten scannen de QR Code en bekijken jouw menu op hun eigen telefoon.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Footer />
            </MainContainer>
        </>
    }
}