import firebase from 'firebase/app';

export class Config {
    static supportedThemeIds = [
        "classic" // ,"bbq"
    ]

    static usesLightBackground(menuId) {
        return ["bbq"].indexOf(menuId) > -1;
    }
} 

export class Tools {
    static themeId(input) {
        if(input && Config.supportedThemeIds.indexOf(input) > -1) {
            return input;
        }

        return Config.supportedThemeIds[0];
    }

    static themeName(input) {
        if(!input) {
            return "Onbekend thema";
        }

        switch(input) {
            case "classic": return "Klassiek";
            case "bbq": return "BBQ & Burgers";
            default: return "Onbekend thema";

            // "classic": "Klassiek",
            //     "bbq": "BBQ",
            //     "easter": "Pasen",
            //     "halloween":"Halloween",
            //     "birthday":"Verjaardag",
            //     "christmas":"Kerst",
            //     "newyearseve": "Oud & Nieuw"
        }
    }
}

class APIError {
    static userNotLoggedIn = new Error("User not logged in.");
}

class Database {
    static menuReference(menuId) {
        return firebase.firestore().collection("cards").doc(menuId);
    }

    static menusCollection() {
        return firebase.firestore().collection("cards");
    }

    static userMenusQuery(userId) {
        return Database.menusCollection().where("uid", "==", userId);
    }
}

export class API {
    static getLoggedInUser() {
        const user = firebase.auth().currentUser
        if (user) {
            return Promise.resolve(user);
        } else {
            return Promise.reject(APIError.userNotLoggedIn);
        }
    }

    static loadMenus() {
        return API.getLoggedInUser().then(function (user) {
            return Database.userMenusQuery(user.uid).get()
        }).then(function (snapshot) {
            return snapshot.docs
        });
    }

    static loadMenu(menuId) {
        return Database.menuReference(menuId).get().then(function (snapshot) {
            let data = snapshot.data();
            if (data) {
                return data.menu;
            }
        });
    }

    static deleteMenu(menuId) {
        return API.getLoggedInUser().then(function (user) {
            return Database.menuReference(menuId).delete();
        });
    }

    static updateMenu(menuId, menuData) {
        return Database.menuReference(menuId).set({
            menu: menuData,
            mdate: firebase.database.ServerValue.TIMESTAMP
        }, {
            merge: true
        }).then(function (documentRef) {
            console.log("Document written with ID: ", documentRef);
            return documentRef;
        }).catch(function (error) {
            console.error("Error adding document: ", error);
        });
    }

    static createMenu(name) {
        return API.getLoggedInUser().then(function (user) {
            return Database.menusCollection().add({
                uid: user.uid,
                cdate: firebase.database.ServerValue.TIMESTAMP,
                menu: {
                    title: name
                }
            }).then(function (documentRef) {
                return documentRef.id;
            });
        });
    }

    static logout() {
        return firebase.auth().signOut();
    }

    static loginWithFacebook() {
        const provider = new firebase.auth.FacebookAuthProvider();
        provider.setCustomParameters({
            'display': 'popup'
        });

        return firebase.auth().signInWithPopup(provider);
    }

    static loginWithGoogle() {
        return firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider());
    }

    static registerWithEmailAndPassword(email, password) {
        return firebase.auth().createUserWithEmailAndPassword(email, password);
    }

    static loginWithEmailAndPassword(email, password) {
        return firebase.auth().signInWithEmailAndPassword(email, password);
    }

    static forgotPassword(emailAddress) {
        return firebase.auth().sendPasswordResetEmail(emailAddress);
    }
}