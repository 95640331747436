import React from 'react';
import { API, Config, Tools } from '../../utils/utils.js';
import { LoaderPage, Footer, ConfirmBox } from '../UI';
import { CardPopup, CardViewer } from './Viewer';
import { MKNavBar } from '../MKNavBar';
import { FormGroup, Input, Button, FormText, Label } from 'reactstrap';
import classNames from 'classnames';
import Select from "react-select"
import CreatableSelect from 'react-select/creatable';
import { CSSTransitionGroup } from 'react-transition-group' // ES6

class Editor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            menuId: null,
            isLoading: false,
            toPreview: false,
            themeName: Tools.themeId(),
            hasUnsavedChanges: false,
            showLivePreview: false,
            canShowSideBySide: false,
            deleteCourseWithId: null
        };

        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.togglePreview = this.togglePreview.bind(this);
        this.handleThemeChange = this.handleThemeChange.bind(this);
        this.courseChangeHandler = this.courseChangeHandler.bind(this);
        this.courseDeleteHandler = this.courseDeleteHandler.bind(this);
        this.courseMoveUpHandler = this.courseMoveUpHandler.bind(this);
        this.courseMoveDownHandler = this.courseMoveDownHandler.bind(this);
        this.addCourse = this.addCourse.bind(this);
        this.updateMenu = this.updateMenu.bind(this);
        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    handleWindowResize() {
        this.setState({
            canShowSideBySide: window.innerWidth >= 720
        });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();

        const { match: { params } } = this.props;

        if (params.menuId) {
            this.setState({
                isLoading: true,
                menuId: params.menuId
            });

            API.loadMenu(params.menuId).then(function (menu) {
                this.setState({
                    ...menu,
                    isLoading: false,
                    error: null
                });
            }.bind(this)).catch(function (error) {
                this.setState({
                    isLoading: false,
                    error: error
                })
            }.bind(this));
        }
    }

    togglePreview(e) {
        this.setState(function (state) {
            return {
                showLivePreview: !state.showLivePreview
            }
        })
    }

    submitHandler(e) {
        e.preventDefault();

        const { match: { params } } = this.props;
        const { title, description, themeName, courses } = this.state;
        const menuData = {
            title: title || "",
            description: description || "",
            themeName: themeName || "",
            courses: courses || []
        };

        // save that stuff!
        API.updateMenu(params.menuId, menuData).then(function () {
            this.setState({
                error: null,
                hasUnsavedChanges: false
            });
        }.bind(this)).catch(function (error) {
            this.setState({
                error: error
            });
        }.bind(this));
    }

    updateMenu(state) {
        this.setState({
            ...state,
            hasUnsavedChanges: true
        });
    }

    handleThemeChange(e) {
        this.updateMenu({
            themeName: e.value
        });
    }

    inputChangeHandler(e) {
        const value = e.target.value;
        const property = e.target.name;

        console.log(property + " = " + value);

        this.updateMenu({
            [property]: value
        });
    }

    addCourse() {
        let courses = this.state.courses || [];
        courses.push({
            id: this.createUniqueId()
        });

        this.updateMenu({
            courses: courses
        });
    }

    createUniqueId() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0;
            var v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    courseChangeHandler(courseId, updatedCourse) {
        this.setState(function (state) {
            let courses = state.courses || [];
            return {
                hasUnsavedChanges: true,
                courses: courses.map(function (course) {
                    if (course.id === courseId) {
                        return updatedCourse
                    }

                    return course;
                })
            }
        });
    }

    courseMoveUpHandler(courseId) {
        let courses = this.state.courses || [];
        let course = courses.find(function (course) {
            return course.id == courseId;
        });
        if (course) {
            let currentIndex = courses.indexOf(course);
            if (currentIndex > 0) {
                let newIndex = currentIndex - 1;
                courses.splice(newIndex, 0, courses.splice(currentIndex, 1)[0]);
                this.setState({
                    hasUnsavedChanges: true,
                    courses: courses
                });
            }
        }
    }

    courseMoveDownHandler(courseId) {
        let courses = this.state.courses || [];
        let course = courses.find(function (course) {
            return course.id == courseId;
        });
        if (course) {
            let currentIndex = courses.indexOf(course);
            if (currentIndex < courses.length - 1) {
                let newIndex = currentIndex + 1;
                courses.splice(newIndex, 0, courses.splice(currentIndex, 1)[0]);
                this.setState({
                    hasUnsavedChanges: true,
                    courses: courses
                });
            }
        }
    }

    courseDeleteHandler(courseId) {
        this.setState({ 
            deleteCourseWithId: courseId 
        });
    }

    render() {
        const courses = this.state.courses || [];

        if (this.state.isLoading) {
            return <LoaderPage />
        } else if (this.state.error) {
            return <pre>{JSON.stringify(this.state.error, null, 4)}</pre>
        } else {
            // let options = {};
            // Config.supportedThemeIds.forEach(function (themeId) {
            //     let themeName = Tools.themeName(themeName);
            //     if (themeName) {
            //         options[themeId] = themeName
            //     }
            // });

            const themeOptions = Config.supportedThemeIds.map(function (themeId) {
                return {
                    value: themeId,
                    label: Tools.themeName(themeId)
                }
            });

            const selectedTheme = themeOptions.find(function (option) {
                return this.state.themeName === option.value;
            }.bind(this));

            return <div className="master-detail">
                <div className={classNames({
                    "master": true,
                    "full": !this.state.canShowSideBySide
                })}>
                    <MKNavBar />
                    <div className="master-content">
                        {themeOptions.length > 1 && <>
                            <FormGroup>
                                <Label>Thema</Label>

                                <Select value={selectedTheme}
                                    options={themeOptions}
                                    onChange={this.handleThemeChange}
                                />

                            </FormGroup>
                        </>}

                        <FormGroup>
                            <Input type="text" name="title" placeholder="Menu"
                                value={this.state.title} onChange={this.inputChangeHandler} />
                            <FormText>De titel bovenaan je kaartje. Kies bijvoorbeeld een leuke naam voor jouw "restaurant".</FormText>
                        </FormGroup>

                        <FormGroup>
                            <Input type="textarea" rows="4" name="description" placeholder="Gelegenheid"
                                value={this.state.description} onChange={this.inputChangeHandler} />
                            <FormText>Een beschrijvend tekstje over het diner, de avond of de gelegenheid, wat jij wilt.</FormText>
                        </FormGroup>

                        <FormGroup>
                            <h5>Gangen</h5>
                            <p>Iedere gang heeft een type, bijvoorbeeld 'Voorgerecht' of 'Hoofdgerecht'. Voeg zoveel gangen toe als je wilt.</p>
                        </FormGroup>

                        <FormGroup>
                            {courses.map(function (course, i) {
                                if (!course.id) { return; }

                                return <CourseEditor
                                    key={course.id}
                                    course={course}
                                    courseIndex={i}
                                    numberOfCourses={courses.length}
                                    onChange={this.courseChangeHandler}
                                    onDelete={this.courseDeleteHandler}
                                    onMoveUp={this.courseMoveUpHandler}
                                    onMoveDown={this.courseMoveDownHandler} />
                            }.bind(this))}
                        </FormGroup>

                        <ConfirmBox
                            title="Verwijderen"
                            body="Weet je zeker dat je deze gang wilt verwijderen?"
                            isOpen={this.state.deleteCourseWithId != null}
                            confirmColor="danger"
                            confirmText="Verwijderen"
                            cancelText="Annuleren"
                            confirm={() => {
                                let courseIdToDelete = this.state.deleteCourseWithId;
                                if(courseIdToDelete == null) {
                                    return;
                                }

                                this.setState(function (state) {
                                    return {
                                        deleteCourseWithId: null,
                                        courses: (state.courses || []).filter(function (course) {
                                            return course.id != courseIdToDelete
                                        })
                                    }
                                });
                            }}
                            cancel={() => {
                                this.setState({ deleteCourseWithId: null });
                            }}
                        />

                        <FormGroup>
                            <Button color="primary" block
                                onClick={this.addCourse}>Gang toevoegen</Button>
                        </FormGroup>

                    </div>
                    <div className="save-button-container">
                        {this.state.hasUnsavedChanges ? <Button block color="primary"
                            onClick={this.submitHandler}>Opslaan</Button> :
                            <div><p className="all-saved">Alles is opgeslagen.</p></div>}
                    </div>
                    <Footer />
                </div>

                {this.state.canShowSideBySide && <div className="detail">
                    <CardViewer menu={this.state} />
                </div>}

                <CardPopup menu={this.state} className={!this.state.canShowSideBySide && this.state.showLivePreview ? "" : "hidden"} />

                {!this.state.canShowSideBySide && <a
                    onClick={this.togglePreview}
                    className={classNames({
                        "preview-button": true,
                        "hide": this.state.showLivePreview
                    })}
                />}

            </div >

        }
    }
}

class CourseEditor extends React.Component {
    handleChange(value, key) {
        if (this.props.course.id === undefined) {
            return
        }

        let updatedCourse = this.props.course
        updatedCourse[key] = value

        this.props.onChange(this.props.course.id, updatedCourse);
    }

    handleTypeChange = (newValue, actionMeta) => {
        this.handleChange(newValue ? newValue.value : null, "type")
    }

    render() {
        const options = [
            "Voorgerecht",
            "Hoofdgerecht",
            "Nagerecht",
            "Tussengerecht",
            "Amuse",
            "Bijgerecht",
            "Soep"
        ].map(function (name) {
            return {
                value: name,
                label: name
            };
        });

        return <div className="edit-course">
            <CreatableSelect
                isClearable
                value={{ label: this.props.course.type, value: this.props.course.type }}
                onChange={this.handleTypeChange}
                options={options} />

            <Input
                placeholder="Naam"
                value={this.props.course.name}
                onChange={e => this.handleChange(e.target.value, "name")} />
            <Input
                type="textarea"
                rows="4"
                maxLength="512"
                placeholder="Beschrijving, ingredienten"
                value={this.props.course.description}
                onChange={e => this.handleChange(e.target.value, "description")} />

            <div className="button-bar">
                <div className="icon delete" onClick={e => this.props.onDelete(this.props.course.id)} ></div>
                {this.props.courseIndex > 0 && <div className="icon up" onClick={e => this.props.onMoveUp(this.props.course.id)} ></div>}
                {this.props.courseIndex < this.props.numberOfCourses - 1 && <div className="icon down" onClick={e => this.props.onMoveDown(this.props.course.id)} ></div>}
            </div>
        </div>
    }
}

export default Editor;