import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import AppContext from '../contexts/AppContext';
import { LoginBox } from './AuthUI';
import {
    Container,
    Navbar,
    NavbarBrand
} from 'reactstrap';

export class MKNavBar extends React.Component {
    static contextType = AppContext;

    state = {
        loginBoxIsOpen: false
    };

    hideLoginBox() {
        this.setState({
            loginBoxIsOpen: false
        });
    }

    render() {
        const isLoggedIn = this.context.user ? true : false;

        return <Navbar className="mknavbar">
            <NavbarBrand>
                <Link to="/">
                    <img src="/images/logo.svg" className="logo" alt="Menukaart.com logo" />
                </Link>
            </NavbarBrand>
            <div className="navigation-button-container">
                <Link to="/about">Over</Link>

                {isLoggedIn && <>
                    <Link to="/cards">Jouw kaartjes</Link>
                    <Link to="/profile">Profiel</Link>
                </>}

                {!isLoggedIn && <Link tag="a" onClick={e => { this.setState({ loginBoxIsOpen: true }) }}>Inloggen</Link>}
            </div>
            {this.state.loginBoxIsOpen && <LoginBox isOpen={this.state.loginBoxIsOpen} toggle={this.hideLoginBox.bind(this)} />}
        </Navbar>
    }
}