import React from 'react';
import AppContext from '../contexts/AppContext';
import { Dialog, ConfirmBox } from './UI';
import { API } from '../utils/utils';
import { Button, Row } from 'reactstrap';
import { Redirect } from 'react-router-dom';

export class LoginBox extends React.Component {
    static contextType = AppContext;

    render() {
        if (this.context.user) {
            return <Redirect to="/cards" />
        } else {
            return <Dialog isOpen={this.props.isOpen} toggle={this.props.toggle}>
                <AuthenticationBox toggle={this.props.toggle} />
            </Dialog>
        }
    }
}

class ForgotPasswordFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            modalIsOpen: false
        }
    }

    toggleModal() {
        this.setState(function (state) {
            return {
                modalIsOpen: !state.modalIsOpen
            }
        });
    }

    render() {
        return <>
            <div className="section-block">
                <input type="text"
                    placeholder="E-mailadres"
                    value={this.state.email}
                    onChange={e => {
                        this.setState({
                            "email": e.target.value
                        })
                    }} />

                <Button color="primary" onClick={e => {
                    API.forgotPassword(this.state.email).then(function () {
                        this.toggleModal(true);
                    }.bind(this)).catch(function (error) {
                        this.toggleModal(true);
                    }.bind(this));
                }}>Verstuur e-mail</Button>
            </div>

            <a className="toggle-mode" onClick={e => {
                this.props.switchMode("login");
            }}>Terug</a>

            <ConfirmBox
                title="Klaar"
                body="Misschien hebben een een mail gestuurd om je wachtwoord te resetten. Maar misschien ook niet."
                isOpen={this.state.modalIsOpen}
                confirm={this.toggleModal.bind(this)}
            />
        </>
    }
}

class LoginFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password: ""
        }
    }

    render() {
        return <>
            <div className="section-block">
                <h5>Inloggen</h5>
                <input type="text"
                    placeholder="E-mailadres"
                    value={this.state.email}
                    onChange={e => {
                        this.setState({
                            "email": e.target.value
                        })
                    }} />

                <input type="password"
                    placeholder="Wachtwoord"
                    value={this.state.password}
                    onChange={e => {
                        this.setState({
                            "password": e.target.value
                        })
                    }} />

                <Button color="primary" onClick={e => {
                    API.loginWithEmailAndPassword(this.state.email, this.state.password);
                }}>Login</Button>

                <a onClick={e => {
                    this.props.switchMode("password");
                }}>Wachtwoord vergeten?</a>
            </div>

            <hr />

            <div className="section-block">
                <h5>Nieuw account</h5>
                <p>Klik op registreren om een nieuw account aan te maken.</p>

                <Button onClick={e => {
                    this.props.switchMode("register");
                }}>Registreren</Button>
            </div>

            <hr />

            <div className="section-block">
                <h5>Gebruik account</h5>
                <p>Of login met...</p>
                <Button className="facebook" onClick={API.loginWithFacebook}>Facebook</Button>
                <Button className="google" onClick={API.loginWithGoogle}>Google</Button>
            </div>
        </>
    }
}

class RegisterFields extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            password1: "",
            password2: ""
        }
    }

    render() {
        return <>
            <div className="section-block">
                <input type="text"
                    placeholder="E-mailadres"
                    value={this.state.email}
                    onChange={e => {
                        this.setState({
                            "email": e.target.value
                        })
                    }} />

                <input type="password"
                    placeholder="Wachtwoord"
                    value={this.state.password}
                    onChange={e => {
                        this.setState({
                            "password1": e.target.value
                        })
                    }} />

                <input type="password"
                    placeholder="Nogmaals je wachtwoord"
                    value={this.state.password}
                    onChange={e => {
                        this.setState({
                            "password2": e.target.value
                        })
                    }} />

                <Button color="primary"
                    onClick={e => {
                        API.registerWithEmailAndPassword(this.state.email, this.state.password1);
                    }}>Aanmelden</Button>
            </div>

            <a className="toggle-mode" onClick={e => {
                this.props.switchMode("login");
            }}>Terug naar inloggen</a>
        </>
    }
}

export class AuthenticationBox extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            mode: "login"
        }
    }

    render() {
        let fields;
        switch (this.state.mode) {
            case "register":
                fields = <RegisterFields switchMode={mode => {
                    this.setState({ mode: mode })
                }} />
                break;
            case "login":
                fields = <LoginFields switchMode={mode => {
                    this.setState({ mode: mode })
                }} />
                break;
            case "password":
                fields = <ForgotPasswordFields switchMode={mode => {
                    this.setState({ mode: mode })
                }} />
                break;
        }

        return <div className="auth-box">
            <h1>Hallo</h1>
            <p>Welkom bij Menukaartje.com!</p>
            {fields}

            <><p /><Button onClick={this.props.toggle} color="light">Annuleren</Button></>
        </div>
    }
}