import React from 'react';
import AppContext from '../contexts/AppContext';
import { ConfirmBox, MainContainer, Footer, Loader } from './UI';
import { Link, Switch, Route } from 'react-router-dom';
import { API } from '../utils/utils';
import { NewMenuPopup } from './NewMenuPopup';
import { Button, Container, Row, Col } from 'reactstrap';
import { MKNavBar } from './MKNavBar';
import _ from 'lodash';

var classNames = require('classnames');

class CardRow extends React.Component {
    render() {
        const doc = this.props.doc;
        const menu = doc.data().menu;
        return <div className="card-row">
            <Link className="restaurant-name" to={"/edit/" + doc.id} >
                {menu.title || "Geen titel"}
            </Link>

            <Link className="icon view" to={"/view/" + doc.id} />

            <a className="icon delete" onClick={() => {
                this.props.onDelete(doc.id);
            }}></a>

            <Link className="icon qr" to={"/qr/" + doc.id} />
        </div>
    }
}

export class Menu extends React.Component {
    static contextType = AppContext;

    render() {
        return <MainContainer>
            <MKNavBar />
            <Container className="content-page">
                <Switch>
                    <Route path="/cards" exact component={CardsComponent} />
                    <Route path="/profile" exact component={ProfileComponent} />
                </Switch>
            </Container>
        </MainContainer>
    }
}

class CardsComponent extends React.Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            menus: [],
            isLoadingCards: true,
            showNewMenuPopup: false,
            deleteDocumentWithId: null
        }
    }

    componentDidMount() {
        this.reload();
    }

    reload() {
        API.loadMenus().then(function (menus) {
            this.setState({
                menus: menus,
                isLoadingCards: false
            });
        }.bind(this)).catch(function (error) {
            this.setState({
                error: error,
                isLoadingCards: false
            });
        }.bind(this));
    }

    render() {
        let textElement;
        if (this.state.isLoadingCards) {
            textElement = <p>Momentje...</p>;
        } else if(this.state.menus.length > 0) {
            textElement = <p>Dit zijn jouw kaartjes, maak er zoveel als je wilt.</p>;
        } else {
            textElement = <p>Zodra je je eerste kaartje gemaakt hebt vind je 'm hier terug.</p>;
        }

        return <>
            <h1>Jouw kaartjes</h1>
            {textElement}
            <div className="card-list">
                {this.state.isLoadingCards ? <Loader /> : this.state.menus.map(function (doc, i) {
                    return <CardRow doc={doc} key={i} onDelete={(menuId) => {
                        this.setState({ deleteDocumentWithId: menuId });
                    }} />
                }.bind(this))}
            </div>

            <ConfirmBox
                title="Verwijderen"
                body="Weet je zeker dat je dit kaartje wilt verwijderen?"
                isOpen={this.state.deleteDocumentWithId != null}
                confirmColor="danger"
                confirmText="Verwijderen"
                cancelText="Annuleren"
                confirm={() => {
                    API.deleteMenu(this.state.deleteDocumentWithId).then(function () {
                        this.reload();
                    }.bind(this)).finally(function () {
                        this.setState({ deleteDocumentWithId: null });
                    }.bind(this));
                }}
                cancel={() => {
                    this.setState({ deleteDocumentWithId: null });
                }}
            />

            <p />

            <Button
                color="primary"
                onClick={e => {
                    this.setState({
                        showNewMenuPopup: true
                    });
                }}>Maak een nieuw kaartje!</Button>

            <NewMenuPopup
                isOpen={this.state.showNewMenuPopup}
                toggle={() => {
                    this.setState({
                        showNewMenuPopup: false
                    });
                }} />

        </>
    }
}

class ProfileComponent extends React.Component {
    static contextType = AppContext;

    render() {
        return <>
            <h1>Profiel</h1>
            <p>Je bent ingelogd als <span className={"user-name " + (_.get(this.context.user, "providerData.0.providerId") || "email").split(".")[0]}>{this.context.userName}</span>.</p>

            {/* <pre>{JSON.stringify(this.context.user, null, 4)}</pre> */}

            <Button
                color="danger"
                onClick={e => {
                    API.logout();
                }}>Uitloggen</Button>
        </>
    }
}
